<nav #navbar class="navbar active">
  <div class="container-fluid" style="background-image: linear-gradient(#878b89, #fffbfb);">
    <div class="navbar-header" style="background-color: white;">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <img class="center" style="width: 200px;margin-left: 15px;margin-top: -10px;" src="assets/images/logo.PNG" alt="" />
      <mat-icon *ngIf="color=='red'" class="Blink man" style="font-size: 30px;padding-top: 10px;color:red;">notifications</mat-icon>
      <mat-icon *ngIf="color=='green'" class="man" style="font-size: 30px;padding-top: 10px;color:green;">notifications</mat-icon>
      <mat-icon *ngIf="color=='yellow'" class="Blink man" style="font-size: 30px;padding-top: 10px;color:yellow;">notifications</mat-icon>
      <mat-icon *ngIf="color=='orange'" class="Blink man" style="font-size: 30px;padding-top: 10px;color:orange;">notifications</mat-icon>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="nav navbar-nav navbar-right" >
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <!-- <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button> -->
          <!-- <input style="margin-top: 12px;" type="text" class="form-control" placeholder="Search"> -->
        </li>
        <!-- #END# Full Screen Button -->
        <!-- <li class="nav-item" ngbDropdown>
          <a ngbDropdownToggle class="lang-dropdown">
            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16">
            <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="16">
          </a>
          <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right pullDown">
            <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{'active': langStoreValue === item.lang}">
              <img src="{{item.flag}}" class="flag-img" height="12"> <span class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li> -->
        <!-- #START# Notifications-->
        <li class="nav-item" ngbDropdown>
          <mat-icon *ngIf="color=='red'" class="Blink" style="font-size: 30px;padding-top: 10px;color:red;">notifications</mat-icon>
          <mat-icon *ngIf="color=='green'" style="font-size: 30px;padding-top: 10px;color:green;">notifications</mat-icon>
          <mat-icon *ngIf="color=='yellow'" class="Blink" style="font-size: 30px;padding-top: 10px;color:yellow;">notifications</mat-icon>
          <mat-icon *ngIf="color=='orange'" class="Blink" style="font-size: 30px;padding-top: 10px;color:orange;">notifications</mat-icon>
          <!-- <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <mat-icon>notifications_none</mat-icon>
            <span class="label-count bg-orange"></span>
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 250px;" [perfectScrollbar]>
              <ul class="menu">
                <li>
                  <a href="#" *ngFor="let notification of notifications" onClick="return false;">
                    <span class="table-img msg-user">
                      <img src="{{notification.userImg}}" alt="">
                    </span>
                    <span class="menu-info">
                      <span class="menu-title">{{notification.userName}}</span>
                      <span class="menu-desc">
                        <i class="material-icons">access_time</i> {{notification.time}}
                      </span>
                      <span class="menu-desc">{{notification.message}}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <a class="text-center d-block border-top py-1">Read
              All Notifications</a>
          </div> -->
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item user_profile" ngbDropdown style="padding-right: 10px;">
          {{userFullName}}
        </li>
        <li class="nav-item user_profile" ngbDropdown style="padding-right: 10px;">
          <img *ngIf="imageshow!=null" src={{userImg}} class="rounded-circle" width="32" height="32" alt="User">
          <img *ngIf="imageshow==null" src="../../../assets/images/default-user.png" class="rounded-circle" width="32" height="32" alt="User">
        </li>
        <!-- #END# Tasks -->
        <li class="pull-right">
          <button mat-icon-button (click)="logout()" class="nav-notification-icons js-right-sidebar">
            <mat-icon id="settingBtn">logout</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
