import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(userName: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}auth/login`, {
        userName,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  requestGetState(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'States';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetMemberList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'member/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetInvestorList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'investor/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetRankList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'rank/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetBookrequisitionList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'bookrequisition/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  myprofile(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Profile';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  userregistration(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/stockpoints';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  userlist(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'auth/users';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  stateList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'state/state';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  terminalList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/terminallist';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  studentadd(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Students';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetCompany(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'company/company';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateCompany(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'company/company';
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  requestGetUserList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'auth/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteUser(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/delete/' + userId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getUser(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'auth/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getWallet(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'UserWallets/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateUser(userId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/user/' + userId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addUser(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/signup';
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getEnquiry(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'enquiry/enquiry/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetEnquiry(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'enquiry/enquiries';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  enquiryadd(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'enquiry/enquiry';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetCategoryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetCategoryListC(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category1/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetCategoryList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  daterangesale(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/businessreport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  itemSale(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/itemsale';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  saleReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/salereport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  hsnSaleReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/hsn-salereport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  purchaseReport(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'purchase/purchasereport';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  daterangesale1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/businessreport1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetCategoryListC1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category1/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetBrandList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'brand/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetTaxList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tax/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }

  requestGetProductList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'product/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetProductLists(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'product/authorss';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetProductListss(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'product/authorsss';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetVendorstockList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'vendorstock/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetBookApproveList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'bookapprove/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetRoleList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'role/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetProductList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'product/booklist';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }

  requestGetPaymentVoucherList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'payment/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  //STOCKPOINT
  stockpointList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  stockpointList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  stockpointList2(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/authors2/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addStockpoint(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getStockpoint(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/author/'+id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateStockpoint(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteStockpoint(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/author/'+id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }


  requestGetVendorcategoryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'vendorcategory/bookcategories';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }

  requestGetItemgroupList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'itemgroup/bookcategories';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  

  requestGetAuthorList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'author/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetUnitList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'unit/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetJobList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'job/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetFreeList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'freeisbn/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetDailyList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'dailyentry/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetLetterList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'letter/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetGrnList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'grn/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetGrnList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'grn/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  searchPurchase(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'purchase/search';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addGrn(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'grn/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addRequest(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'payment/request';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addGrn1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'grn/create';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getGrn(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'grn/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getItemList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/product/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetBillingList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'billingaddress/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetBankList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'bankdetail/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetPurchaseList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'purchase/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetSaleList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetSaleList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'salepackage/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetOutsourceList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'outsource/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetPaymentList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'paymentterm/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetLogisticList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'logisticdetail/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetEwayuomList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'ewayuom/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetUomList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'uom/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetBsaList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'bsa/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetItemCategoryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'product/author2';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetVendorsList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'bsa/vendors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetCustomerList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'bsa/customers';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetBuyersList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'bsa/buyers';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetBuyerCompany(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'bsa/company';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetDeliveryCompany(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'deliveryaddress/company';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetStoreList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'store/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetProductnoList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'productnoformat/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  kycUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'member/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  kycUpload1(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'investor/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  productUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'product/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  itemcategoryUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'category/upload/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  profileUpload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'member/upload1/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  profileUpload1(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'investor/upload1/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  requestGetProductorderList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'productorderformat/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetProductsaleList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'productsaleformat/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getTax(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'tax/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  totalSale(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/totalsale';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  monthlySale(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/monthlysale';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  monthlySale1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/monthlysale1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  monthlyMember(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'member/monthlymember';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  reorderedProduct(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/reorderproduct';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetProductoutsourceList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'productoutsourceformat/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetTermconditionList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'termcondition/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetDeliveryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'deliveryaddress/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetCommissionList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'commission/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetStateList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'state/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetBookList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'book/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetVendorList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'vendor/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetSubjectList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subject/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetComplaintList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'complaint/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetComplaintList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'complaint/authors1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetTargetList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'target/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetItemList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'item/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetMarketingList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'marketing/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetCustomertypeList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'customertype/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;

        
      })
    )
  }
  requestGetDistrictList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'district/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGeSubjectList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subject/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getGameList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'game/games';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  gameadd(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'game/game';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getGame(gameId): Observable<any> {
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'game/game/'+gameId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  gamedelete(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'game/game/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  approveRequest(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'payment/approve/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  rejectRequest(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'payment/reject/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteRequest(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'payment/delete/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetGame(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'game/gamerates';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  plrList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/plrlist';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  searchGame(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'gameassign/gameassign/'+ id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addgame(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'gameassign/gameassign';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  plrDashboard(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'PlrDashboard';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  searchPlrList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockPoints2/'+ id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteCategory(categoryId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'category/author/' + categoryId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteCategoryC(categoryId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'category1/author/' + categoryId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteVendorcategory(categoryId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'vendorcategory/bookcategory/' + categoryId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteItemgroup(categoryId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'itemgroup/bookcategory/' + categoryId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteAuthor(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'author/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteCustomertype(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customertype/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteBook(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'book/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteBilling(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'billingaddress/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteDelivery(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'deliveryaddress/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteBank(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bankdetail/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteProduct(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'product/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deletePackage(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'product/package/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteSale(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteSale1(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'salepackage/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  levelIncome(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/levelincome/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  levelIncome1(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'salepackage/levelincome/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  approveSale(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/approve/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  approveSale1(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/approve1/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  approveSale2(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/approve2/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  _approveSale(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'salepackage/approve/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  _approveSale1(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'salepackage/approve1/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  _approveSale2(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'salepackage/approve2/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  approveOutsource(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'outsource/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  geneology(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/directmember/' + authorId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteStore(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'store/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deletePayment(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'paymentterm/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteLogistic(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'logisticdetail/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteUom(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'uom/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteProductno(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'productnoformat/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteProductorder(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'productorderformat/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteProductsale(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'productsaleformat/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteProductoutsource(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'productoutsourceformat/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteBsa(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bsa/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteMember(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteInvestor(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'investor/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteKyc(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/kyc/' + userId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteKyc1(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'investor/kyc/' + userId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteCategorySrc(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'category/category/' + userId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteProductSrc(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'product/product/' + userId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteProfile(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/profile/' + userId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteProfile1(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'investor/profile/' + userId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteTermcondition(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'termcondition/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteVendor(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'vendor/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteTarget(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'target/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteBookdetail(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bookdetail/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteVendordetail(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'vendordetail/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteCommission(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'commission/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteUnit(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'unit/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteJob(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'job/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteItem(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'item/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteMarketing(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'marketing/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteCustomer(authorId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author/' + authorId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  
  getCategory(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getBookRequisition(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'bookrequisition/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getCategoryC(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'category1/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getVendorcategory(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'vendorcategory/bookcategory/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getItemgroup(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'itemgroup/bookcategory/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getAuthor(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'author/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getUnit(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'unit/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getJob(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'job/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getFree(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'freeisbn/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getDaily(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'dailyentry/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getLetter(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'letter/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getBilling(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'billingaddress/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getBank(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'bankdetail/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getProduct(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'product/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getProduct1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'product/author1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getPassword(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'member/author1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getPassword1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'investor/author1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getMember(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'member/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getStatus(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/status/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getStatus1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/status1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateOpeningBv(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'member/updateopeningbv/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getStatus2(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/status1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getInvestor(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'investor/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getMember1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'member/mymember/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getTeam(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'member/myteam/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  monthlyTeam(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'member/monthlyteam/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  personalMember(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'member/personalmember/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  monthlypersonalMember(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'member/monthlypersonalmember/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  personalBusiness(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/personalbusiness/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  teamBusiness(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/teambusiness/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  monthlypersonalBusiness(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/monthlypersonalbusiness/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  businessChart(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/businesschart/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  memberChart(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'member/memberchart/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  targetBar(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'target/targetbar/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  targetBar1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'target/targetbar1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  achieverList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'target/achieverlist/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  packageList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'product/packages';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  monthlyteamBusiness(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/monthlyteambusiness/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getTeam1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'member/myteam1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getRank(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'rank/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getRank1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'rank/author1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getBookapprove(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'bookapprove/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getPurchase(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'purchase/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getSale(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'sale/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getSale1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'salepackage/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getOutsource(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'outsource/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getStore(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'store/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getPayment(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'paymentterm/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getBillingList(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'billingaddress/list/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getLogistic(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'logisticdetail/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getUom(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'uom/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getBsa(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'bsa/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getState(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'state/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getProductno(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'productnoformat/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getProductorder(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'productorderformat/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getProductsale(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'productsaleformat/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getProductoutsource(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'productoutsourceformat/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getTermcondition(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'termcondition/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getDelivery(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'deliveryaddress/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getCommission(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'commission/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getBook(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'book/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getVendor(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'vendor/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getSubject(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subject/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getComplaint(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'complaint/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getTarget(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'target/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getCustomer(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'customer/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getMarketing(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'marketing/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getItem(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'item/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getCustomertype(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'customertype/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  
  getCategoryList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Categories1';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateCategory(categoryId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'category/author/' + categoryId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  paymentRequest(categoryId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'payment/payment/' + categoryId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateBookRequisition(categoryId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bookrequisition/author/' + categoryId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateCategoryC(categoryId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'category1/author/' + categoryId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateVendorcategory(categoryId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'vendorcategory/bookcategory/' + categoryId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateItemgroup(categoryId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'itemgroup/bookcategory/' + categoryId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateAuthor(authorId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'author/author/' + authorId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateUnit(authorId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'unit/author/' + authorId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateJob(authorId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'job/author/' + authorId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateCommission(authorId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'commission/author/' + authorId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateCustomer(authorId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author/' + authorId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateBook(authorId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'book/author/' + authorId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateBookdetail(authorId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'book/bookdetail/' + authorId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateVendor(authorId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'vendor/author/' + authorId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateTarget(authorId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'target/author/' + authorId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateMarketing(authorId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'marketing/author/' + authorId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateItem(authorId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'item/author/' + authorId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateCustomertype(authorId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customertype/author/' + authorId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateDistrict(districtId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'district/author/' + districtId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addCategory(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'category/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addBookrequisition(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bookrequisition/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addCategoryC(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'category1/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addVendorcategory(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'vendorcategory/bookcategory';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addItemgroup(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'itemgroup/bookcategory';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addAuthor(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'author/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addUnit(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'unit/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addJob(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'job/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addFree(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'freeisbn/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addDaily(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'dailyentry/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addLetter(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'letter/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addCommission(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'commission/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addCustomer(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customer/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addBook(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'book/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addBrand(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'brand/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  performanceList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'business/performancelist';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  
  levelincomeList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'business/levellist';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  changeSponsor(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/changesponsor/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  levelincomeList1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'business/levellist1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  performanceCal(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'business/performance';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  performanceCal1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'business/performance1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  updateLevel(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'business/updatelevelincome';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  updateLevel1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'business/updatelevelincome1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  mylevel(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'business/level/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  mylevel1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'business/level1/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  myperformance(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'business/performance/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addVendor(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'vendor/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addSubject(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'subject/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addComplaint(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'complaint/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addComplaint1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'complaint/author1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addTarget(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'target/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }

  addBookdetail(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bookdetail/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addVendordetail(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'vendordetail/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addItem(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'item/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addMarketing(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'marketing/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addCustomertype(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'customertype/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    ) 
  }
  addDistrict(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'district/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addWallet(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'userwallet/userwallet';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  /* addSubject(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'subject/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  } */
  addRole(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'role/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getRole(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'role/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateRole(userId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'role/author/' + userId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addTime(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BatchTimes';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetCourseList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GetsatCourses';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteCourse(courseId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GetsatCourses/' + courseId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteBookAuthor(courseId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'product/bookauthor/' + courseId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteBookApprove(courseId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bookapprove/author/' + courseId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteBookRequisition(courseId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bookrequisition/author/' + courseId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteBookRequisitionItem(courseId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bookrequisition/item/' + courseId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteBookApproveDetail(courseId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bookapprove/book/' + courseId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  approveBookApproveDetail(courseId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bookapprove/approvebook/' + courseId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addCouse(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GetsatCourses';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addChapter(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GetsatChapters';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getChapter(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'GetsatChapters/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getQuestion(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'TopicQuestions/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getSuperStockist(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/stockpoint/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getUser1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'auth/user/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateCourse(courseId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GetsatCourses/' + courseId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateSuperstockist(superstockistId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'stockpoint/updatestockpoint/' + superstockistId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateTime(timeId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BatchTimes/' + timeId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateQuestion(questionId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'TopicQuestions/' + questionId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateChapter(chapterId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GetsatChapters/' + chapterId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteChapter(chapterId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GetsatChapters/' + chapterId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteTime(timeId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BatchTimes/' + timeId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteTopic(topicId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GetsatTopics/' + topicId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addTopic(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GetsatTopics';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteLink(topicId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'TopicLinks/' + topicId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addLink(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'TopicLinks';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addYoutube(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'TopicYoutube';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteQus(topicId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'TopicQuestions/' + topicId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addQuestion(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'TopicQuestions';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addSuperstockist(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'stockpoint/stockpoint';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  studentBatch(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StudentBatches';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  teacherBatch(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'TeacherBatches';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateSubject(subjectId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'subject/author/' + subjectId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateTopic(topicId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GetsatTopics/' + topicId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteSubject(subjectId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'subject/author/' + subjectId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteComplaint(subjectId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'complaint/author/' + subjectId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteDistrict(subjectId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'district/author/' + subjectId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getCourse(courseId): Observable<any> {
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GetsatCourses/'+ courseId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getDistrict(districtId): Observable<any> {
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'district/author/'+ districtId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getTopic(topicId): Observable<any> {
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GetsatTopics/'+ topicId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getSubjectList1(courseId): Observable<any> {
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'GetsatSubjects1/'+ courseId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  /* getSubject(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'subject/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  } */
  requestGetTeacherList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Teachers';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetTeacherList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'TeacherPagination';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetSsList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockPoints/Superstockpoint';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  userLimitationList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'userlimitation/userlimitations';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  userWalletList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/userwalletlist';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  userWalletList1(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'userwallet/userwalletlist/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetSList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockPoints/Stockpoint';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetStockpoint(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockPoints1/Stockpoint';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  pageList(url): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = url;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getTeacher(teacherId): Observable<any> {
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Teachers/'+ teacherId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getSubjectList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'SubjectList';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getCourseList(categoryId): Observable<any> {
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'CourseList/'+ categoryId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateTeacher(teacherId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Teachers/' + teacherId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateBatch(batchId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Batches/' + batchId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateLimitation(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'userlimitation/userlimitation/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updatePayout(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'stockpoint/updatepayout/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updatePayoutList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'stockpoint/updateallpayout';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteTeacher(teacherId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Teachers/' + teacherId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteStockist(stockistId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'stockpoint/stockpoint/' + stockistId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteBatch(batchId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Batches/' + batchId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  upload(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'StudentImage/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  upload1(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'FatherImage/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  upload2(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'MotherImage/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  upload3(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'TeacherImage/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  upload4(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'AddressImage/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  upload5(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'XResultImage/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }

  upload6(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'XiiResultImage/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  upload7(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'CollegeResultImage/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  upload8(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'TopicImage/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  upload9(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'TopicPdf/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  upload10(id, formData) {
    return this.http.post<any>(`${environment.apiUrl + 'TopicVideo/' + id }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  bannerUpload(formData) {
    return this.http.post<any>(`${environment.apiUrl + 'banner/upload' }`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //catchError(this.handleError)
    );
  }
  requestGetBannerList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'banner/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteBanner(userId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'banner/author/' + userId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
		break;
      case HttpEventType.Response:
        return this.apiResponse(event);
		break;
      default:
        return `File "${formData.get('profile').name}" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  changepassword(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/user/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  changepassword1(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'auth/password/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addteachercourse(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'TeacherCourses';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addteacher(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Teachers';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addBatch(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Batches';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  forgotPwd(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/forgot';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  sendOtp(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'payment/sendotp';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  allotCourse(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StudentCourseAllots';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  teachercoursedelete(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'TeacherCourses/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetStudentList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Students';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetStudentList2(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StudentList';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetTeacherList2(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'TeacherList';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetBatchList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'Batches';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetStudentList1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StudentPagination';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetPList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockPoints/Plr';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  requestGetPlr(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'StockPoints1/Plr';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  myProfile1(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
    let httpAuthOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        }
      ), 
    };
    let URL = environment.apiUrl + 'stockpoint/myprofile';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getStudent(studentId): Observable<any> {
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Students/'+ studentId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getTime(timeId): Observable<any> {
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'BatchTimes/'+ timeId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getBatch(batchId): Observable<any> {
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Batches/'+ batchId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getBatchStudent(batchId): Observable<any> {
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StudentBatches/'+ batchId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getAllotedCourseList(studentId): Observable<any> {
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StudentCourseAllots/'+ studentId;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addStudent(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Students';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteStudent(studentId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Students/' + studentId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteStudentBatch(studentId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StudentBatches/' + studentId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteStudentBatch1(studentId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StudentBatches1/' + studentId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteTeacherBatch(teacherId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'TeacherBatches/' + teacherId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  deleteTeacherBatch1(teacherId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'TeacherBatches1/' + teacherId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  deleteAllot(allotId): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'StudentCourseAllots/' + allotId;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  updateStudent(studentId, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Students/' + studentId;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updatePin(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'stockpoint/updatepin/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateBilling(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'billingaddress/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateBillingAccess(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'billingaddress/access/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateBank(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bankdetail/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateProduct(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'product/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateProduct1(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'product/author1/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateBookApprove(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bookapprove/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateStore(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'store/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updatePayment(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'paymentterm/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateLogistic(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'logisticdetail/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateUom(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'uom/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateBsa(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bsa/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateMember(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateInvestor(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateMembers(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/authors/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateMember1(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/author1/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateInvestor1(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'investor/author1/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateInvestor2(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'investor/author2/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateProductno(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'productnoformat/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateProductorder(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'productorderformat/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateProductsale(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'productsaleformat/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateProductoutsource(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'productoutsourceformat/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateTermcondition(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'termcondition/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateDelivery(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'deliveryaddress/author/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateTerminal(id, data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'stockpoint/updateterminal/' + id;
    return this.http.put<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  siblingdelete(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Siblings/' + id;
    return this.http.delete<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  siblingadd(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'Siblings';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addBilling(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'billingaddress/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addBank(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bankdetail/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addProduct(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'product/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addProduct1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'product/package';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  stockDetail(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'vendorstock/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateStockAdd(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'updatestock/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  stockTransfer(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'stocktransfer/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  stockTransferList(): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'stocktransfer/authors';
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  stockTransferDetail(id): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'stocktransfer/author/' + id;
    return this.http.get<any>(URL, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  updateStockReduce(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'updatestock/author1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  currentStock(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'updatestock/current';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addBookApprove(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bookapprove/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addPurchase(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'purchase/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addSale(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addSalePackage(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'salepackage/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addSale1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/product';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addOutsource(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'outsource/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addStore(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'store/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getProductList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'product/author1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getPurchaseList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'purchase/author1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getSaleList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'sale/author1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  getSaleList1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'salepackage/author1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  getOutsourceList(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'outsource/author1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addPayment(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'paymentterm/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addLogistic(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'logisticdetail/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addEwayuom(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'ewayuom/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addUom(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'uom/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addBsa(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'bsa/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addMember(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addInvestor(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'investor/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  searchMember(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/sponsor';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  searchMember1(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'member/sponsor1';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  addProductno(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'productnoformat/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addProductorder(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'productorderformat/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addProductsale(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'productsaleformat/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addProductoutsource(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'productoutsourceformat/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addTermcondition(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'termcondition/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }
  addDelivery(data): Observable<any>{
    let token = localStorage.getItem("MYTOKEN");
      let httpAuthOptions = {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          }
        ), 
      };
    let URL = environment.apiUrl + 'deliveryaddress/author';
    return this.http.post<any>(URL, data, httpAuthOptions).
    pipe(
      map(res =>{
        return res;
      })
    )
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem('MYTOKEN');
        localStorage.removeItem('USERNAME');
        localStorage.removeItem('USERTYPE');
        localStorage.removeItem('USERID');
        localStorage.removeItem('USERCODE');
        localStorage.removeItem('MYSRC');
        localStorage.removeItem('BILLINGID');
        localStorage.removeItem('menus');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
